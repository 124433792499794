import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import Post from '@webPages/Journal/Post';
import { BlogQuery } from '@generated/GraphqlTypes';
import decodeBlogDetailed from '@decoders/BlogDetailed';

const BlogPostPage: React.FC<PageProps<BlogQuery>> = ({ data }) => {
  const blog = decodeBlogDetailed(data);

  const title =
    data.markdownRemark?.frontmatter?.seoTitle || `${blog.title} | Ecoliv`;
  const description =
    data.markdownRemark?.frontmatter?.seoDescription ||
    data.markdownRemark?.excerpt ||
    undefined;
  const image = data.markdownRemark?.frontmatter?.thumb?.publicURL || undefined;

  return (
    <Base theme="light" title={title} description={description} image={image}>
      <Post blog={blog} />
    </Base>
  );
};

export default BlogPostPage;

export const query = graphql`
  query Blog($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        video
        embed
    
        fileLabel
        tags
        seoTitle
        seoDescription
        thumb {
          publicURL
        }
      }
      excerpt
      html
    }
  }
`;
