import { BlogQuery } from '@generated/GraphqlTypes';
import BlogDetailed from '@localTypes/BlogDetailed';
import { decodeTags } from './Blogs';

const decodeBlogs = (data: BlogQuery): BlogDetailed => {
  const slug = `${data.markdownRemark?.fields?.slug}`;
  const tags = decodeTags(
    (data.markdownRemark?.frontmatter?.tags || []).filter(a => !!a) as string[]
  );

  return {
    title: `${data.markdownRemark?.frontmatter?.title}`,
    slug,
    html: `${data.markdownRemark?.html}`,
    date: new Date(data.markdownRemark?.frontmatter?.date) || new Date(),
    video: data.markdownRemark?.frontmatter?.video || undefined,
    embed: data.markdownRemark?.frontmatter?.embed || undefined,
    file: data.markdownRemark?.frontmatter?.file?.publicURL || undefined,
    fileLabel: data.markdownRemark?.frontmatter?.fileLabel || undefined,
    tags,
  };
};

export default decodeBlogs;
